// ProfileCard.jsx
import React from 'react';
import './LogoProfileCard.css';

const LogoProfileCard = () => {
  return (
    <div className="profile-card">
      <img src={require("../../images/profilelogo.jpg")} alt={"PK's"} className="profile-image" />
      {/* <div className="profile-name">{name}</div> */}
    </div>
  );
};

export default LogoProfileCard;
