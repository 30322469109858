import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavigationHome.css";
import { FaHome, FaUser, FaReact, FaSearch } from "react-icons/fa";
import {
  SiMicrosoftsharepoint,
  SiMicrosoftazure,
  SiMicrosoftoffice,
} from "react-icons/si";
import { GiHamburgerMenu, GiMushroomCloud } from "react-icons/gi";
import { BsFillCloudLightningRainFill } from "react-icons/bs";
import { MdOutlineWork } from "react-icons/md";
import { ImProfile } from "react-icons/im";

import LogoProfileCard from "../ProfileCard/LogoProfileCard";

function NavigationHome(props) {
  const { onSearch } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [click, setClick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [clickMicrosoft, setClickMicrosoft] = useState(false);
  const [isOpenMicrosoft, setIsOpenMicrosoft] = useState(false);

  const location = useLocation();
  const [actLocation, setActiveLocation] = useState("/");

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function handleSearchInputChange(event) {
    onSearch(event.target.value);
  }

  function handleMenuButtonClick() {
    setIsMenuOpen(!isMenuOpen);
  }
  function handleLinkClick() {
    setClick(!click);
    setActiveLocation(location.pathname);
  }

  function handleLinkClickdropdown() {
    if (window.innerWidth <= 960) {
      // setClick(false);
      setIsOpen(!isOpen);
    } else {
      setClick(!click);
    }
  }

  function handleLinkClickMicrosoft() {
    setClick(!click);
    setActiveLocation(location.pathname);
  }

  const handleDropdownMicrosoft = () => {
    setIsOpenMicrosoft(!isOpenMicrosoft);
  };

  const handleDropdownMouseOutMicrosoft = () => {
    setIsOpenMicrosoft(false);
  };

  const handleDropdownMouseOut = () => {
    setIsOpen(false);
  };

  function handleLinkClickdropdownMicrosoft() {
    if (window.innerWidth <= 960) {
      // setClick(false);
      setIsOpenMicrosoft(!isOpenMicrosoft);
    } else {
      setClick(!click);
    }
  }

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">

        <video autoPlay loop muted className="background-video">
            <source src="/techdots.mp4" type="video/mp4"/>
          </video>

          {/* <li onClick={handleLinkClick}> */}
          <Link onClick={handleLinkClick} className="nav-logo" to="/">
            <LogoProfileCard />
            <span>
              Pk's <BsFillCloudLightningRainFill /> TechBytes{" "}
            </span>
          </Link>
          {/* </li> */}
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li
              className={
                location.pathname == "/" || location.pathname == ""
                  ? "nav-item active"
                  : "nav-item"
              }
              onClick={handleLinkClick}
            >
              <Link onClick={handleLinkClick} className="nav-links" to="/">
                <FaHome />
                <span className="navspan">Home</span>
              </Link>
            </li>

            <li
              className={
                location.pathname == "/azure" ? "nav-item active" : "nav-item"
              }
              onClick={handleLinkClick}
            >
              <Link
                to="/azure"
                className={"nav-dropdown-item nav-links"}
                onClick={handleLinkClickMicrosoft}
              >
                <span className="navspan">
                  {" "}
                  <SiMicrosoftazure /> Azure
                </span>
              </Link>
            </li>

            <li
              className={
                location.pathname == "/sharepoint"
                  ? "nav-item active"
                  : "nav-item"
              }
              onClick={handleLinkClick}
            >
              <Link
                to="/sharepoint"
                className={"nav-dropdown-item nav-links"}
                onClick={handleLinkClickMicrosoft}
              >
                <span className="navspan">
                  <SiMicrosoftsharepoint /> Sharepoint
                </span>
              </Link>
            </li>

            {/* <li
              className={
                location.pathname == "/react" ? "nav-item active" : "nav-item"
              }
              onClick={handleLinkClick}
            >
              <Link to="/react" className="nav-links">
                <FaReact />
                <span className="navspan">React</span>
              </Link>
            </li> */}

            <li
              className={"nav-item nav-aboutmeli"}
              onClick={handleLinkClickdropdown}
            >
              <div className="nav-dropdown">
                <div
                  className="nav-dropdown-toggle nav-links"
                  onMouseOver={handleDropdown}
                >
                  <FaUser />
                  <span className="navspan">About Me</span>
                </div>

                <div
                  className={
                    isOpen
                      ? "nav-dropdown-menu open"
                      : "nav-dropdown-menu close"
                  }
                  onMouseOut={handleDropdownMouseOut}
                >
                  {/* <Link
                    to="/my-works"
                    className={"nav-dropdown-item nav-links"}
                   
                   
                    onClick={handleLinkClick}
                  >
                    <span className="navspan"> <MdOutlineWork/> My Works</span>
                  </Link> */}
                  <Link
                    to="/about-me"
                    className={"nav-dropdown-item nav-links"}
                    onClick={handleLinkClick}
                  >
                    <span className="navspan">
                      <ImProfile /> My Profile
                    </span>
                  </Link>
                </div>
              </div>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleLinkClick}>
            <GiHamburgerMenu
              className={click ? "fas fa-times" : "fas fa-bars"}
            ></GiHamburgerMenu>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}
          </div>
        </div>
        {/* <div className="search-container">
          <input type="text" placeholder="Search tiles" onChange={handleSearchInputChange} />
          <button className="search-button">
            <FaSearch />
          </button>
        </div> */}
      </nav>
    </>
  );
}

export default NavigationHome;
