import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGithub, FaYoutube } from 'react-icons/fa';
import { atob } from 'atob';


function Tile({ tile }) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    let url;

    if (tile.PdfUrl && tile.PdfUrl.Url) {
      url = `${(tile.PdfUrl.Url)}`;
    } else if (tile.YouTubeUrl) {
      url = tile.YouTubeUrl;
    }

    if (url) {
      window.open(url, '_blank');
    }
  };

  // backgroundImage: `url(${tile.ImageBlob ? `data:image/png;base64,${tile.ImageBlob}` : require("../images/defaultheader.png")})`

  return (
    <div className='tile containertiles'>
    <div
      className="tileimages"
      style={{
        backgroundImage: `url(${require("../images/defaultheader.png")})`,
        height:60,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center', // Center vertically
       justifyContent: 'center', // Center horizontally
      }}
    >
      <p style={{color: 'white', textAlign: 'center', width: '70%', fontSize:'12px', fontWeight:600}}> {tile.Title} </p>
      </div>
      <div className="tile-content">
        {/* <div className="tile-header">
          <div className="tile-title">{tile.Title}</div>
        </div> */}
        <p className="tile-description">{tile.Description}</p>
        <div className="tile-footer">
          <div className="tile-buttons">
            {tile.GitHubRepo && (
              <button className="tile-button" onClick={() => window.open(tile.GitHubRepo, '_blank')}>
                <FaGithub style={{ color: '#333333' }} />
              </button>
            )}
            {tile.YouTubeUrl && (
              <button className="tile-button" onClick={() => window.open(tile.YouTubeUrl, '_blank')}>
                <FaYoutube style={{ color: '#ff0000' }}/>
              </button>
            )}
              <a href="/" className="tile-link" onClick={handleClick}>
              Learn more
            </a>
          </div>          
        </div>
      </div>
      </div>
  );
  
  
}

export default Tile;
