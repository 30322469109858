import React, { useState } from 'react';
import './SearchBox.css';

const SearchBox = (props) => {
  const { onSearch, value } = props;
  const [searchTerm, setSearchTerm] = useState('');

  function handleSearchInputChange(event) {
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  }

  function handleClearClick() {
    setSearchTerm('');
    onSearch('');
  }

  function handleSubmit(event) {
    event.preventDefault();
    onSearch(searchTerm);
  }

  return (
    <div className="search-box-wrapper">
      <div className="search-container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="search-input"
            value={value}
            onChange={handleSearchInputChange}
            placeholder="Search..."
          />
          <i className="search-icon">🔍</i>
          <button className="clear-button" onClick={handleClearClick}>
            Clear
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchBox;
