import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import "@fontsource/pacifico";
import axios from "axios";
import jsonData from './Offlinedata/blogsitejson.json';  
import InformativeLoader from "./components/Loader/InformativeLoader";
import "./App.css";
import NavigationHome from "./components/Navigation/NavigationHome";
import YouTube from "./components/Youtube";
import Tile from "./components/Tile";
import { FaSpinner } from "react-icons/fa";
import * as Loader from "react-loader-spinner";
import Profile from "./components/ProfileCard/Profile";
import PdfViewer from "./components/PdfViewer";
import ReadmeViewer from "./components/ReadmeViewer";
import SearchBox from "./components/Searchbox/SearchBox";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import qs from "qs";
import Pagination from 'react-js-pagination';

// add the fontawesome icon to the library
library.add(faSpinner);

function MainContent() {
  const location = useLocation();
  const isNotHomePage =
    location.pathname !== "/" && location.pathname !== "/about-me";

  const [tiles, setTiles] = useState([]);
  const [tilesAzure, setTilesAzure] = useState([]);
  const [tilesSharepoint, setTilesSharepoint] = useState([]);
  const [tilesReact, setTilesReact] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      await fetchTiles();
    };
    
    fetchData();
  }, []);

  // useEffect( async () => {
  // //   const fetchTilesData = async () => {
  // //   const cachedTiles = JSON.parse(localStorage.getItem("tiles")) || [];

  // //   if (cachedTiles.length > 0) {
  // //     setTiles(cachedTiles);
  // //     setFilteredTiles(cachedTiles);
  // //     setLoading(false);
  // //   } else {
  // //   await fetchTiles();
      
  // //   }
  // // };
  // await fetchTiles();
  // }, []);

  const setFilteredTiles = (tiles) => {
    fetchAzureTiles(tiles);
    fetchSharepointTiles(tiles);
    fetchReactTiles(tiles);
  };

const fetchAzureTiles=async(tiles)=>{
  const azureTiles = tiles.filter((tile) => tile.BlogType === "Azure");
  setTilesAzure(azureTiles)
}
const fetchSharepointTiles=async(tiles)=>{
  const sharepointTiles = tiles.filter((tile) => tile.BlogType === "Sharepoint");
  setTilesSharepoint(sharepointTiles)
}
const fetchReactTiles=async(tiles)=>{
  const reactTiles = tiles.filter((tile) => tile.BlogType === "Reactjs");
  setTilesReact(reactTiles)
}

  const fetchTiles = async () => {
    setLoading(true);

    try {
      // server side node js call to get teh sharepoint data from oauth client
        // server side node js call to get the sharepoint data from oauth client
       // https://praveenblognodeserververcel.vercel.app/api/listitems
    
      setTiles(tiles);
    //   fetchInitialData();const response = await axios.get(`https://chatgptfunctions.azurewebsites.net/api/blogsite`, {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    //     "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    //   }
    // });
    //   const tiles = response.data;
    //   setFilteredTiles(tiles);
    //   localStorage.setItem("tiles", JSON.stringify(tiles));


    // Sort jsonData based on the ID field in descending order
    jsonData.sort((a, b) => b.ID - a.ID);
      setTiles(jsonData);
      setFilteredTiles(jsonData);
      

    

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearCache = () => {
    localStorage.removeItem("tiles");
    setTiles([]);
  };

  const handleSearch = async (searchTerm) => {
    setLoading(true);

    const siteUrl = "https://your-sharepoint-site";
    const listName = "Tiles";

    const appConfig = {
      clientId: "your-client-id",
      clientSecret: "your-client-secret",
      tenantId: "your-tenant-id",
    };

    const authUrl = `https://accounts.accesscontrol.windows.net/${appConfig.tenantId}/tokens/OAuth/2`;

    const tokenConfig = {
      grant_type: "client_credentials",
      client_id: appConfig.clientId,
      client_secret: appConfig.clientSecret,
      resource: `${siteUrl}/_api/`,
    };

    const response = await axios.post(authUrl, tokenConfig);
    const accessToken = response.data.access_token;

    const listUrl = `${siteUrl}/_api/web/lists/getbytitle('${listName}')/items?$filter=substringof('${searchTerm}',Title)`;

    const listResponse = await axios.get(listUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const listData = listResponse.data;
    setTiles(listData.value);

    localStorage.setItem("tiles", JSON.stringify(listData.value));
    setLoading(false);
  };

  const handleAzureSearch = (searchTerm) => {
    if (searchTerm === "") {
      setTilesAzure(tiles.filter((tile) => tile.BlogType === "Azure"));
    } else {
      const filteredAzureTiles = tilesAzure.filter((tile) =>
        tile.Title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTilesAzure(filteredAzureTiles);
    }
  };
  
  const handleSharepointSearch = (searchTerm) => {
    if (searchTerm === "") {
      setTilesSharepoint(tiles.filter((tile) => tile.BlogType === "Sharepoint"));
    } else {
    const filteredSharepointTiles = tilesSharepoint.filter((tile) =>
      tile.Title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTilesSharepoint(filteredSharepointTiles);
    }
  };

  const handleReactSearch = (searchTerm) => {
    if (searchTerm === "") {
      setTilesReact(tiles.filter((tile) => tile.BlogType === "React"));
    } else {
    const filteredReactTiles = tilesReact.filter((tile) =>
      tile.Title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTilesReact(filteredReactTiles);
    }
  }; 

  const fetchInitialData = () => {
    const azureTiles = tiles.filter((tile) => tile.BlogType === "Azure");
    const sharepointTiles = tiles.filter((tile) => tile.BlogType === "Sharepoint");
    const reactTiles = tiles.filter((tile) => tile.BlogType === "Reactjs");
  
    setTilesAzure(azureTiles);
    setTilesSharepoint(sharepointTiles);
    setTilesReact(reactTiles);
  };
  


  const getSearchHandler = () => {
    if (location.pathname.toLowerCase() === "/azure") {
      return handleAzureSearch;
    } else if (location.pathname.toLowerCase() === "/sharepoint") {
      return handleSharepointSearch;
    } else if(location.pathname.toLowerCase()==="/react") {
      return handleReactSearch;
    }
  };

  const handleClear = () => {
    setSearchValue("");
    fetchInitialData();
  };

  
  return (
    <div>
      <NavigationHome onSearch={handleSearch} onClearCache={handleClearCache} />
      {isNotHomePage && (
  <SearchBox
    value={searchValue}
    onSearch={(value) => {
      setSearchValue(value);
      getSearchHandler()(value);
    }}
  />
)}

      <Routes>
        <Route path="/about-me" element={<Profile className="profile-background" />} />
        <Route path="/Azure" element={<TilesListRoutes tiles={tilesAzure} />} />
        <Route path="/Sharepoint" element={<TilesListRoutes tiles={tilesSharepoint} />} />
        <Route path="/React" element={<TilesListRoutes tiles={tilesReact} />} />
        <Route path="/pdf/:pdfUrl" element={<PdfViewer />} />
        <Route path="/readme/:owner/:repo" element={<ReadmeViewer />} />
        <Route path="/youtube/:youtubeUrl" element={<YouTube />} />
        <Route
          path="/"
          element={
            loading ? (
              // <div className="loader-container">
              //   <Loader.TailSpin
              //     type="Puff"
              //     color="#00BFFF"
              //     height={100}
              //     width={100}
              //   />
              // </div>
             <><InformativeLoader></InformativeLoader></> 
            ) : (
              <TileList tiles={tiles} />
              // <InformativeLoader></InformativeLoader>
            )
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

//sub routes tiles like sharepoint, azure, react for pagination
function TilesListRoutes({ tiles }) {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const indexOfLastTile = activePage * itemsPerPage;
  const indexOfFirstTile = indexOfLastTile - itemsPerPage;
  const currentTiles = tiles.slice(indexOfFirstTile, indexOfLastTile);
  
  console.log(tiles);
  return (
    <div>
      <div className="tiles-list">
        {currentTiles.map((tile, index) => (
          <Tile key={index} tile={tile} />
        ))}
      </div>
      {tiles.length>10 &&
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={tiles.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
        prevPageText="&laquo;"
        nextPageText="&raquo;"
      />
}
    </div>
  );
}


//homecomponent
function TileList({ tiles }) {
  const azureTiles = tiles
    .filter((tile) => tile.BlogType === "Azure")
    .slice(0, 5);
  const sharepointTiles = tiles
    .filter((tile) => tile.BlogType === "Sharepoint")
    .slice(0, 5);
  // const reactTiles = tiles
  //   .filter((tile) => tile.BlogType === "Reactjs")
  //   .slice(0, 5);

  return (
    <div className="tile-list">
      <div className="tile-section">
        <h2 className="tile-H2Home">
          Streamline Your Business with SharePoint
        </h2>
        <div className="tiles">
          {sharepointTiles.map((tile) => (
            <Tile key={tile.ID} tile={tile} />
          ))}
        </div>
        <div className="load-more">
          <Link className="blogLoadMoreHome" to="/sharepoint">
            Load More
          </Link>
        </div>
      </div>   
      <div className="tile-section">
        <h2 className="tile-H2Home">
          Welcome to the Cloud: Discover the Power of Azure
        </h2>
        <div className="tiles">
          {azureTiles.map((tile) => (
            <Tile key={tile.ID} tile={tile} />
          ))}
        </div>
        <div className="load-more">
          <Link className="blogLoadMoreHome" to="/azure">
            Load More
          </Link>
        </div>
      </div>
       
      {/* <div className="tile-section">
        <h2 className="tile-H2Home">
          Building Dynamic Web Applications with ReactJS
        </h2>
        <div className="tiles">
          {reactTiles.map((tile) => (
            <Tile key={tile.ID} tile={tile} />
          ))}
        </div>
        <div className="load-more">
          <Link className="blogLoadMoreHome" to="/react">
            Load More
          </Link>
        </div>
      </div> */}
    </div>
  );
}

export default App;
