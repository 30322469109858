import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {marked} from 'marked';
import axios from 'axios';

function ReadmeViewer() {
  const { owner, repo } = useParams();
  const readmeUrl = `https://raw.githubusercontent.com/${owner}/${repo}/main/README.md`;
  const [readme, setReadme] = useState('');

  useEffect(() => {
    const fetchReadme = async () => {
      try {
        const response = await axios.get(readmeUrl);
        setReadme(response.data);
      } catch (error) {
        console.error(error);
        setReadme('Error fetching README.md');
      }
    };

    fetchReadme();
  }, [readmeUrl]);

  return (
    <div className="readme-viewer">
      <div dangerouslySetInnerHTML={{ __html: marked(readme) }}></div>
    </div>
  );
}

export default ReadmeViewer;
