import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfViewer() {
  const pdfUrl = decodeURIComponent(window.location.pathname.slice(5));
  const corsProxy = 'https://cors-anywhere.herokuapp.com/';
  const proxiedPdfUrl = corsProxy + pdfUrl;

  return (
    <div className="pdf-viewer">
      <Document file={proxiedPdfUrl.replace(/^https?:\/\/[^/]+\//i, '')}>
        <Page pageNumber={1} />
      </Document>
    </div>
  );
}

export default PdfViewer;
