import React, { useState } from "react";
import "./ProfileCardHead1.css";


function ProfileCardHead1() {

  // const [activeSection, setActiveSection] = useState("#about");

  const buttons = document.querySelectorAll(".card-buttons button");

  const handleButtonClick = (e) => {
    const sections = document.querySelectorAll(".card-section");
const card = document.querySelector(".card");
    const targetSection = e.target.getAttribute("data-section");
  const section = document.querySelector(targetSection);
  targetSection !== "#about"
    ? card.classList.add("is-active")
    : card.classList.remove("is-active");
  card.setAttribute("data-state", targetSection);
  // setActiveSection(targetSection);
  sections.forEach((s) => s.classList.remove("is-active"));
  buttons.forEach((b) => b.classList.remove("is-active"));
  e.target.classList.add("is-active");
  section.classList.add("is-active");
  };

  const handleAboutClick = (e) => {
    //setActiveSection("#about");
    const sections = document.querySelectorAll(".card-section");
    const card = document.querySelector(".card");
        const targetSection = e.target.getAttribute("data-section");
      const section = document.querySelector(targetSection);     
     card.classList.add("is-active");     
      card.setAttribute("data-state", targetSection);
      // setActiveSection(targetSection);
      sections.forEach((s) => {
        if (s.id !== "about") {
          s.classList.remove("is-active");
        }
        else if(s.id=='about')
        {
          s.classList.add("is-active")
        }
      });
  
      buttons.forEach((b) =>{
        if (b.id !== "btnabout") {
          b.classList.remove("is-active");
        }
        else if(b.id=='btnabout')
        {
          b.classList.add("is-active")
        }
      });
      //  card-section
      e.target.classList.add("is-active");
      section.classList.add("is-active");
  };

  // buttons.forEach((btn) => {
  //   btn.addEventListener("click", handleButtonClick);
  // });

  return (
    <>
      <div className="card" data-state='#about'>
        <div className="card-header">
          <div
            className="card-cover"
            style={{
              backgroundImage:
                'linear-gradient(to right, #8360c3, #2ebf91)',
            }}
          />
          <img
            className="card-avatar"
            src={require("./image/praveenpic.jpg")}
            alt="avatar"
          />
          <h1 className="card-fullname">Praveen Kumar</h1>
          <h2 className="card-jobtitle">Cloud Technical Specialist</h2>
        </div>
        <div className="card-main">
          <div className="card-section is-active" id="about">
            <div className="card-content">
              <div className="card-subtitle">ABOUT</div>
              <p className="card-desc">
              I'm a skilled tech professional, fluent in Azure, SharePoint, Reactjs, Python, and .NET. Whether building Apps or designing solutions, I always strive for precision and innovation. When not coding, I enjoy exploring the vibrant arts scenes of cities around the world.And at the end of the day, there's nothing more satisfying than knowing I've created something that will make people's lives easier and more enjoyable.
              </p>
            </div>
            <div className="card-social">
              {/* <a href="#">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z" />
                </svg>
              </a> */}
              <a target="_blank" href="https://twitter.com/Pravinmee">
                <svg className="profileTwitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z" />
                </svg>

              </a>
              {/* <a href="#">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="M301 256c0 24.852-20.148 45-45 45s-45-20.148-45-45 20.148-45 45-45 45 20.148 45 45zm0 0" />
                  <path d="M332 120H180c-33.086 0-60 26.914-60 60v152c0 33.086 26.914 60 60 60h152c33.086 0 60-26.914 60-60V180c0-33.086-26.914-60-60-60zm-76 211c-41.355 0-75-33.645-75-75s33.645-75 75-75 75 33.645 75 75-33.645 75-75 75zm86-146c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" />
                  <path d="M377 0H135C60.562 0 0 60.563 0 135v242c0 74.438 60.563 135 135 135h242c74.438 0 135-60.563 135-135V135C512 60.562 451.437 0 377 0zm45 332c0 49.625-40.375 90-90 90H180c-49.625 0-90-40.375-90-90V180c0-49.625 40.375-90 90-90h152c49.625 0 90 40.375 90 90zm0 0" />
                </svg>
              </a> */}
              <a target="_blank" href="https://www.linkedin.com/in/pravin080317/">
                <svg className="profileLink" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 002.882 0z" />
                </svg>
              </a>
              <a target="_blank" href="https://www.youtube.com/channel/UCiLghwJGKyyDVse8v6bWOzg">
  <svg className="profileYoutube" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path d="M567.28 143.22c-6.11-22.94-24.36-40.68-47.28-46.79C464.58 80 288 80 288 80S111.88 80 56.06 96.41C33.14 102.53 15 120.28 8.89 143.22 0 180.16 0 256 0 256s0 75.84 8.89 112.78c6.11 22.94 24.36 40.68 47.28 46.79C111.88 432 288 432 288 432s176.12 0 231.94-16.41c22.92-6.11 41.17-23.85 47.28-46.79C576 331.84 576 256 576 256s0-75.84-8.72-112.78z" fill="#f00"/>
    <path d="M405.16 265.42l-98.18 57.3c-19.17 11.22-35.44-2.91-35.44-22.91V212.89c0-20 16.27-34.13 35.44-22.91l98.18 57.3c16.46 9.64 16.46 33.06 0 42.7z" fill="#fff"/>
  </svg>
</a>

<a target="_blank" href="https://github.com/pravin080317">
  <svg className="profileGithub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12 .3c-6.63 0-12 5.37-12 12 0 5.302 3.438 9.8 8.207 11.387.6.11.793-.258.793-.576 0-.284-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61-.546-1.388-1.333-1.756-1.333-1.756-1.089-.744.083-.729.083-.729 1.204.084 1.838 1.236 1.838 1.236 1.07 1.84 2.808 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.303-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.524.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.652.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.604-.015 2.896-.015 3.286 0 .318.187.693.8.575 4.77-1.588 8.203-6.085 8.203-11.387 0-6.63-5.373-12-12-12z" />
  </svg>
</a>

            </div>
          </div>
          <div className="card-section" id="experience">
            <div className="card-content">
              <div className="card-subtitle">WORK EXPERIENCE</div>
              <div className="card-timeline">
              <div className="card-item" data-year={2022}>
                  <div className="card-item-title">
                    Solution Architect at <span>Cognizant</span>
                  </div>
                  <div className="card-item-desc">
                  In my role as an architect, I have been responsible for providing technical solutions and insights for Microsoft technologies, specifically SharePoint,Power Platform, Reactjs, Azure, and Office 365. This has involved analyzing requirements, designing and developing solutions, collaborating with business stakeholders, and leading technical teams. Additionally, I have been responsible for managing project timelines, budgets, and resources to ensure that projects are delivered on time and within budget.
                  </div>
                </div>
                <div className="card-item" data-year={2017}>
                  <div className="card-item-title">
                    Technical Lead Manager at <span>LTI</span>
                  </div>
                  <div className="card-item-desc">
                  Enhance and Built Campus:Developing a knowledge collaboration web application for Microsoft
                  </div>
                </div>
                <div className="card-item" data-year={2015}>
                  <div className="card-item-title">
                    Technical Lead at <span>Cognizant</span>
                  </div>
                  <div className="card-item-desc">
                    Design & Built Digital Workplace: Developing a global digital platform for employees of a paints and coatings company
                  </div>
                </div>
                <div className="card-item" data-year={2013}>
                  <div className="card-item-title">
                    Lead Programmer and Sharepoint Migration specialist at<span>Cognizant</span>
                  </div>
                  <div className="card-item-desc">
                  Migrating confidential documents from MOSS 2007 to SharePoint 2013 for an oil and gas company.
                  This involved careful planning and execution to ensure that data was moved with integrity and security intact.
                  </div>
                </div>  
              </div>
            </div>
          </div>
          <div className="card-section" id="contact">
            <div className="card-content">
              <div className="card-subtitle">CONTACT</div>
              <div className="card-contact-wrapper">
                <div className="card-contact">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                    <circle cx={12} cy={10} r={3} />
                  </svg>
                  Chennai, TN, India
                </div>
                <div className="card-contact">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z" />
                  </svg>
                  (+91) 7530061277
                </div>
                <div className="card-contact">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                    <path d="M22 6l-10 7L2 6" />
                  </svg>
                  pravin080317@gmail..com
                </div>
                <button className="contact-me"><a href="https://www.linkedin.com/in/pravin080317/" target="_blank">WORK TOGETHER</a></button>
              </div>
            </div>
          </div>
          <div className="card-buttons">
            <button id='btnabout' data-section="#about" onClick={handleAboutClick} className="is-active">
              ABOUT
            </button>
            <button id='btnexperience' onClick={handleButtonClick} data-section="#experience">EXPERIENCE</button>
            <button id='btncontact' onClick={handleButtonClick} data-section="#contact">CONTACT</button>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProfileCardHead1;
