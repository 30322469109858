import React from 'react';
import * as Loader from "react-loader-spinner";
import "./InformativeLoader.css";

// Sample data for loader messages
const loaderMessages = [
    "Did you know? Our app can help you manage your tasks efficiently.",
    "Tip: Use our search feature to find your tasks quickly.",
    "Fun fact: The first computer bug was an actual bug trapped in a computer.",
    "Fun fact: The first registered domain name was symbolics.com, registered on March 15, 1985.",
    "Did you know? The original name for Windows was Interface Manager.",
    "Fun fact: The first webcam was developed at the University of Cambridge to monitor the Trojan coffee pot.",
    "Fun fact: The first known computer game, 'Spacewar!', was invented by Steve Russell in 1962.",
    "Did you know? The first microprocessor, the Intel 4004, was designed for a calculator.",
    "Fun fact: The Python programming language is not named after the snake, but after the British comedy group Monty Python.",
    "Did you know? The 'C' in the programming language 'C++' is an increment operator, meaning that C++ is one step beyond C.",
    "Fun fact: The first computer virus was created in 1983 by Fred Cohen, a PhD student at the University of Southern California.",
    "Did you know? The first 'Hello, World!' program was introduced in the book 'The C Programming Language' by Brian Kernighan and Dennis Ritchie.",
    "Did you know? The name 'Google' is derived from 'googol', a term for a 1 followed by 100 zeros.",
    "Fun fact: The first 1GB hard drive weighed about 550 pounds and cost $40,000.",
    "Did you know? CAPTCHA stands for 'Completely Automated Public Turing test to tell Computers and Humans Apart'.",
    "Tip: If you want to type faster, you might want to try the Dvorak Simplified Keyboard.",
    "Fun fact: Linux was named after its creator Linus Torvalds.",
    "Did you know? The first version of the Java programming language was called Oak.",
    "Fun fact: The world's first website, info.cern.ch, is still online.",
    "Did you know? Amazon.com was originally called Cadabra.",
    "Fun fact: The first-ever VCR, made in 1956, was the size of a piano.",
    "Did you know? The original Apple logo featured Sir Isaac Newton sitting underneath a tree."
  ];
  

class InformativeLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.getRandomMessage(),
    };
  }

  componentDidMount() {
    // Change the message every 5 seconds
    this.interval = setInterval(() => {
      this.setState({
        message: this.getRandomMessage(),
      });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getRandomMessage() {
    return loaderMessages[Math.floor(Math.random() * loaderMessages.length)];
  }

  render() {
    return (
        <div style={{ textAlign: "center" }}>
        <Loader.TailSpin
        wrapperStyle={{ color: 'lightgray', padding: '10px', borderRadius: '5px', display:'block' }}        
          type="Puff"
          color="rgb(13, 93, 101)"
          height={70}
          width={70}
        />
         <p style={{ color: "rgb(13 93 101)", fontSize: "20px", fontWeight: "bold" }}>
          Page is loading, please wait...
        </p>
        <p class="gradient-text" style={{ color: "#3F51B5",        
 fontSize: "16px" }}>
          {this.state.message}
        </p>
      </div>
    );
  }
}

export default InformativeLoader;
