import React from "react";
// import marked from "marked";
import "./Profile.css";
import ProfileCardHead1 from "./ProfileCardHead1";
// import ProfileAnimationIconsTech from "./ProfileAnimationIconsTech";

function Profile() {
  return (  

    <div className="profile-page">
   

{/* <ProfileAnimationIconsTech></ProfileAnimationIconsTech> */}
      <div className="content">
        <div className="container">
          {/* START: PAGE CONTENT */}

          <div className="col-xs-12 profilecardcontainer">
            <ProfileCardHead1></ProfileCardHead1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
